
<template>
<div id="user-edit-tab-info">
  <form class="" autocomplete="off">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="firstName">First name</label>
                <input id="firstName" type="text" class="form-control" v-model="data.first_name"
                v-validate="'required|alpha_spaces'" placeholder="First name" name="first_name" />
                <span class="text-danger font-sm"  v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="LastName">Last name</label>
                <input id="LastName" type="text" class="form-control" v-model="data.last_name"
                v-validate="'required|alpha_spaces'" placeholder="Last Name" name="last_name" />
                <span class="text-danger font-sm"  v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="useremail">Email Address</label>
                <input id="useremail" :readonly="!!data.is_verified" label="Email" v-model="data.email" type="email" v-validate="'required|email'" name="email" class="form-control"/>
                <span class="text-danger font-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
            </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="username">Username</label>
            <input id="username" autocomplete="false" type="text" class="form-control"
            v-model="data.username" v-validate="{required: false, regex: '^\\+?([a-z0-9]+)$' }" placeholder="username" name="username" />
            <span class="text-danger font-sm"  v-show="errors.has('username')">{{ errors.first('username') }}</span>
          </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="user-mobile">Mobile</label>
                <input id="user-mobile" type="text" autocomplete="off" class="form-control" v-model="data.mobile" v-validate="{regex: '^\\+?([0-9+]+)$' }" placeholder="mobile" name="mobile" />
                 <span class="text-danger font-sm"  v-show="errors.has('mobile')">{{ errors.first('mobile') }}</span>
            </div>
        </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="">Role</label>
          <select class="custom-select" v-validate="{required: true}" v-model="data.role" name="input-role" placeholder="--select role--">
            <option disabled value="" key="-1">-- select role--</option>
            <option :value="role.name" v-for="(role, index) in roleOptions" :key="index">{{role.name | capitalize}}</option>
          </select>
          <span class="text-danger invalid-feedback"  v-show="errors.has('input-role')">{{ errors.first('input-role') }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="">Status</label>
          <select class="custom-select" v-validate="{required: true}" v-model="data.status" name="input-status" placeholder="--select status--">
            <option disabled value="" key="-1">-- select status --</option>
            <option :value="status.value" v-for="(status, index) in statusOptions" :key="index">{{status.label}}</option>
          </select>
          <span class="text-danger invalid-feedback"  v-show="errors.has('input-status')">{{ errors.first('input-status') }}</span>
        </div>
      </div>
      <div class="col-md-6 d-flex align-items-end">
        <div class="form-group">
          <label class="d-block">Verification</label>
            <b-form-checkbox v-model="data.is_verified" name="is-verified" switch>
              {{data.is_verified? 'Verified' : 'Not Verified'}}
            </b-form-checkbox>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <b-form-group label="Gender" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="data.gender"
              :options="genderOptions"
              :aria-describedby="ariaDescribedby"
              name="radio-gender"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div class="col-12">
        <vue-dropzone id="profileImageDrop" ref="profileImageDrop"
         :use-custom-slot="true" :maxFiles="1">
          <div class="dz-message my-0 needsclick">
            <i class="h1 text-muted ri-upload-cloud-2-line"></i>
            <h4>Drop files here or click to upload.</h4>
            <span class="text-muted font-13">
                (Dimension should be 450 x 450 Max: 100kb)
            </span>
          </div>
        </vue-dropzone>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-md-6">
        <div class="text-right">
          <button type="button" @click.prevent="updateUser()" class="btn btn-success mt-2">
              <i class="mdi mdi-content-save"></i> Update
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
</template>

<script>
import VueDropzone from '@/components/VueDropzone.vue'
export default {
  components: { VueDropzone },
  data() {
    return {
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Others', value: 'others' }
      ],
      statusOptions: [
        {label: "Active", value:'active'},
        {label: "Deactivated", value:'deactivated'},
        {label: "Blocked", value:'blocked'}
      ],
      data: {
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        mobile: "",
        gender: "",
        status: "",
        is_verified: false,
        role: "",
      },
    }
  },
  computed: {
    roleOptions() {
      return this.$store.state.userList.roles.map(role => {
        return {
          name: role.name,
          label: role.name
      }})
    },
    user(){
      return this.$store.state.userList.user
    }
  },
  methods: {
    updateUser() {
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        if(this.collectFiles().length){formData.append('image_file', this.collectFiles()[0]);}
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/users/${this.user.id}/update`,formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
        })
      })
    },
    patchUser(){
      this.data = this.$options.filters.patchUpdatables(this.data, this.user)
    },
    collectFiles(){
        var arrafile = [];
        if(this.$refs.profileImageDrop){
          arrafile = [...this.$refs.profileImageDrop.getAcceptedFiles()];
        }
        return arrafile
    },
    resetForm() {
      this.$refs.profileImageDrop.removeAllFiles();
    },
  },
  created(){
    this.patchUser()
  },
}
</script>
